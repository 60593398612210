<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.DTManagement.title.NewDetectionTask')"
      @head-cancel="cancelFn"
      @head-save="saveFn('save')"
      @head-saveback="saveFn('back')"
    >
    </head-layout>
    <div style="background: #fff; padding: 20px">
      <el-form
        :rules="rules"
        :model="addform"
        :disabled="disabled"
        label-position="right"
        label-width="80px"
        ref="ruleForm"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item
              :label="$t('cip.dc.QTemplate.field.inputData')"
              prop="taskName"
            >
              <el-input v-model="addform.taskName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="width: 100%; height: 20px"></div>
        <el-divider content-position="left">{{
          $t("AiSuanfa.detail.placeholder")
        }}</el-divider>
        <div style="width: 100%; height: 20px"></div>
        <el-row>
          <el-col :span="6">
            <el-form-item
              :label="$t('cip.dc.QTemplate.field.DataTheme')"
              prop="sourceId2"
            >
              <el-cascader
                :show-all-levels="false"
                v-model="addform.sourceId2"
                :options="sourceList"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  expandTrigger: 'hover',
                }"
                style="width:100%"
                @change="sourceChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :label="$t('cip.dc.QTemplate.field.DataObject')"
              prop="dataObjectId"
            >
              <el-select v-model="addform.dataObjectId" @change="sourceChange2" style="width:100%">
                <el-option
                  v-for="(item, index) in sourceList2"
                  :key="index"
                  :label="item.code"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item
              :label="$t('cip.dc.QTemplate.field.LimitTheNumberOfEntries')"
              prop="maxLimit"
            >
              <el-input-number
                :controls="false"
                v-model.number="addform.maxLimit"
                :min="0"
                :label="$t('cip.dc.QTemplate.field.LimitTheNumberOfEntries')"
                style="width:100%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('cip.dc.CJudgmentTask.field.remarks')">
              <el-input  v-model="addform.remark" style="width:100%">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row>
        <el-col
          :span="12"
          v-show="
            $route.query.algorithm_id != 8 && $route.query.algorithm_id != 41
          "
        >
          <p>{{ $t("cip.dc.QTemplate.field.CompareFields") }}</p>
          <el-table
            border
            @selection-change="handleSelectionChangey1"
            :data="columnList"
            style="width: 100%"
            ref="y1table"
            @select="selecty1"
            :header-cell-class-name="leftheaderStyle"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="fieldComment"
              :label="$t('AiSuanfa.detail.zh')"
            >
            </el-table-column>
            <el-table-column
              prop="fieldName"
              :label="$t('cip.dc.QTemplate.field.field')"
            >
            </el-table-column>
            <el-table-column
              prop="dataType"
              :label="$t('cip.dc.QTemplate.field.dataType')"
            >
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <p>{{ $t("cip.dc.QTemplate.field.sortField") }}</p>
          <el-table
            border
            @selection-change="handleSelectionChange"
            :data="columnList"
            style="width: 100%"
            ref="x1table"
            @select="selectx1"
            :header-cell-class-name="leftheaderStyle"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="fieldComment"
              :label="$t('AiSuanfa.detail.zh')"
            >
            </el-table-column>
            <el-table-column
              prop="fieldName"
              :label="$t('cip.dc.QTemplate.field.field')"
            >
            </el-table-column>
            <el-table-column
              prop="dataType"
              :label="$t('cip.dc.QTemplate.field.dataType')"
            >
            </el-table-column>
            <el-table-column
              prop="dataType"
              :label="$t('cip.dc.QTemplate.field.sort')"
            >
              <template slot-scope="scope">
                <el-select
                  class="tableSclect"
                  v-model="scope.row.sortRule"
                  :placeholder="$t('cip.dc.QTemplate.field.sort')"
                >
                  <el-option :label="$t('cip.dc.dataobjDComission.upsort')" value="0"> </el-option>
                  <el-option :label="$t('cip.dc.dataobjDComission.downsort')" value="1"> </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getDataTree,
  ObjectList,
  ObjectList2,
  getDataOBJXQ2,
  SaveTask,
  TaskDetail,
} from "@/api/dataAcquisition/index";

export default {
  name: "zljcDetail",
  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-saveback",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      rules: {
        taskName: [
          { required: true, message: this.$t('cip.dc.qualityDComission.rule1'), trigger: "change" },
        ],
        sourceId2: [
          { required: true, message: this.$t('cip.dc.qualityDComission.rule2'), trigger: "change" },
        ],
        dataObjectId: [
          { required: true, message: this.$t('cip.dc.qualityDComission.rule3'), trigger: "change" },
        ],

        maxLimit: [
          {
            type: "number",
            required: true,
            message: this.$t('cip.dc.qualityDComission.rule4'),
            trigger: "change",
          },
        ],
      },

      addform: {
        templateId: "",
        taskName: "", //任务名称
        subjectPid: null, //主题域
        subjectId: null, //主题
        sourceAllId: [],
        dataObjectId: "", //数据对象id
        dataObjectName: "", //数据对象名称
        maxLimit: undefined,
        taskContent: {
          //任务内容
          //对比字段
          compareFields: [],
          sortFields: [],
          maxLimit: undefined, //限制条数
        },
        remark: "", //备注
      },

      tableData: [],
      algorithmList: [],
      sourceList: [],
      sourceList2: [],
      biaoList: [],
      columnList: [],
      multipleSelection: [],
      disabled: false,
    };
  },
  created() {
    this.MgetDataTree();
    if (this.$route.query.type == "xunlian") {
      this.addform.templateId = this.$route.query.templateTypeId;
    }
    if (this.$route.query.type == "edit") {
      TaskDetail({ id: this.$route.query.id }).then((res) => {
        this.addform = res.data.data;
        this.addform.id = res.data.data.id;
        //处理数据主题
        this.addform.sourceId2 = [
          res.data.data.subjectPid,
          res.data.data.subjectId,
        ];
        //回显数据对象
        this.sourceChange(this.addform.sourceId2);

        this.addform.maxLimit = res.data.data.taskContent.maxLimit;
        //回显字段
        // this.sourceChange2(res.data.data.dataObjectId)
        getDataOBJXQ2({
          id: res.data.data.dataObjectId,
        }).then((res) => {
          this.columnList = res.data.data.saveFields;
          this.columnList.forEach((element) => {
            this.$set(element, "sortRule", "");
          });
          //  this.$set(item, "BCinput", "")
          console.log(this.columnList, this.$refs.y1table.toggleRowSelection);
          setTimeout(() => {
            this.addform.taskContent.compareFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (
                  element.fieldId != -1 &&
                  element.fieldId == element2.fieldId
                ) {
                  this.$refs.y1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                }
                if (
                  element.fieldId == -1 &&
                  element.fieldName == element2.fieldName
                ) {
                  this.$refs.y1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                }
              });
            });

            //排序字段
            this.addform.taskContent.sortFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (
                  element.fieldId != -1 &&
                  element.fieldId == element2.fieldId
                ) {
                  this.$refs.x1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  element2.sortRule = element.sortRule.toString();
                }
                if (
                  element.fieldId == -1 &&
                  element.fieldName == element2.fieldName
                ) {
                  this.$refs.x1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  element2.sortRule = element.sortRule.toString();
                }
              });
            });
          }, 1);
        });
        this.disabled = false;
      });
    }
    if (this.$route.query.type == "see") {
      TaskDetail({ id: this.$route.query.id }).then((res) => {
        this.addform = res.data.data;
        //处理数据主题
        this.addform.sourceId2 = [
          res.data.data.subjectPid,
          res.data.data.subjectId,
        ];
        //回显数据对象
        this.sourceChange(this.addform.sourceId2);
        this.addform.maxLimit = res.data.data.taskContent.maxLimit;
        //回显字段
        // this.sourceChange2(res.data.data.dataObjectId)

        getDataOBJXQ2({
          id: res.data.data.dataObjectId,
        }).then((res) => {
          this.columnList = res.data.data.saveFields;
          this.columnList.forEach((element) => {
            this.$set(element, "sortRule", "");
          });
          //  this.$set(item, "BCinput", "")
          console.log(this.columnList, this.$refs.y1table.toggleRowSelection);
          setTimeout(() => {
            this.addform.taskContent.compareFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (element.fieldId == element2.fieldId) {
                  console.log(element.fieldId, element2.fieldId);
                  this.$refs.y1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  console.log(this.columnList[index]);
                }
              });
            });

            //排序字段
            this.addform.taskContent.sortFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (element.fieldId == element2.fieldId) {
                  this.$refs.x1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  element2.sortRule = element.sortRule.toString();
                }
              });
            });
          }, 1);
        });

        this.disabled = true;
      });
    }
  },

  methods: {
    leftheaderStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "seltAllbtnDis";
      }
    },
   //数据主题
    MgetDataTree() {
      getDataTree({ object: 0 }).then((res) => {
        if (res.status == 200) {
          this.sourceList = res.data.data;
          this.sourceList.forEach((element) => {
            element.children.forEach((element2) => {
              if (element2.children) {
                element2.children = undefined;
              }
            });
          });
        }
      });
    },

   

    sourceChange(value) {
      let parms = {
        modelId: value[1],
        query: { current: 1, size: 100 },
      };

      ObjectList(parms).then((res) => {
        this.sourceList2 = res.data.data.records;
      });
    },
    sourceChange2(value) {
      this.sourceList2.forEach((element) => {
        if (element.id == value) {
          this.addform.dataObjectName = element.code;
        }
      });
      getDataOBJXQ2({
        id: value,
      }).then((res) => {
        this.columnList = res.data.data.saveFields;
      });
    },

    //单选，后期支持多选放开
    selectx1(selection, row) {
      // // 清除 所有勾选项
      this.$refs.x1table.clearSelection();
      // // 当表格数据都没有被勾选的时候 就返回
      // // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.x1table.toggleRowSelection(row, true);
    },

    selecty1(selection, row) {
      // // 清除 所有勾选项
      this.$refs.y1table.clearSelection();
      // // 当表格数据都没有被勾选的时候 就返回
      // // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.y1table.toggleRowSelection(row, true);
    },

    cancelFn() {
      this.$router.go(-1);
    },
    //新增保存任务
    saveFn(e) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$refs.y1table.selection.length < 1) {
            this.$message({
              message: this.$t('cip.dc.qualityDComission.rule5'),
              type: "warning",
            });
          }
          //   if(this.$refs.x1table.selection.length<1){
          //     this.$message({
          //   message: '请选择排序字段和排序',
          //   type: 'warning'
          //  });
          //   }
          else {
            let addParms = JSON.parse(JSON.stringify(this.addform));
            addParms.subjectPid = this.addform.sourceId2[0];
            addParms.subjectId = this.addform.sourceId2[1];
            addParms.taskContent.maxLimit = this.addform.maxLimit;
            addParms.taskContent.compareFields = [];
            this.$refs.y1table.selection.forEach((element) => {
              if (element.fieldId != -1) {
                addParms.taskContent.compareFields.push({
                  fieldId: element.fieldId,
                });
              } else {
                addParms.taskContent.compareFields.push({
                  fieldName: element.fieldName,
                });
              }
            });
            addParms.taskContent.sortFields = [];
            this.$refs.x1table.selection.forEach((element) => {
              if (element.fieldId != -1) {
                addParms.taskContent.sortFields.push({
                  fieldId: element.fieldId,
                  sortRule: element.sortRule,
                });
              } else {
                addParms.taskContent.sortFields.push({
                  fieldName: element.fieldName,
                  sortRule: element.sortRule,
                });
              }
            });

            addParms.sourceId2 = undefined;
            addParms.sourceAllId = undefined;
            addParms.maxLimit = undefined;

            SaveTask(addParms).then((res) => {
              if (e == "back") {
                //  this.$router.go(-1);
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
              this.$message({
                message: this.$t('cip.dc.offlineDComission.savemsg'),
                type: "success",
              });
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
